import { storeToRefs } from "pinia";

import useProductRouting from "shared/composables/useProductRouting";
import features from "shared/services/features";
import Permissions from "shared/services/Permissions";
import { useGlobalStore } from "shared/stores/global";

import { getLocaleText } from "./i18n";

let permissions;
let appInstance;

const { routeProductName } = useProductRouting();
const isMediaProduct = () =>
  routeProductName(appInstance.config.globalProperties.$route) === "media";
const isSocialProduct = () =>
  routeProductName(appInstance.config.globalProperties.$route) === "social";
const isOutreachProduct = () =>
  routeProductName(appInstance.config.globalProperties.$route) === "outreach";

export const organisationFeatures = {
  canManageBrandProfiles() {
    return (
      (isMediaProduct() && permissions.has("can_manage_brand_profiles")) ||
      (isSocialProduct() &&
        permissions.has("can_manage_brand_profiles", "social")) ||
      (isOutreachProduct() &&
        permissions.has("can_manage_brand_profiles", "outreach"))
    );
  },
  canManageDistributionLists() {
    return (
      (isMediaProduct() && permissions.has("can_manage_distribution_lists")) ||
      (isOutreachProduct() &&
        permissions.has("can_manage_distribution_lists", "outreach"))
    );
  },
  canManageUsers() {
    return (
      (isMediaProduct() && permissions.has("can_manage_organisation_users")) ||
      (isSocialProduct() &&
        permissions.has("can_manage_organisation_users", "social")) ||
      (isOutreachProduct() &&
        permissions.has("can_manage_organisation_users", "outreach"))
    );
  },
  canViewOrganisationUsage() {
    return (
      (isMediaProduct() && permissions.has("can_view_organisation_usage")) ||
      (isSocialProduct() &&
        permissions.has("can_view_organisation_usage", "social")) ||
      (isOutreachProduct() &&
        permissions.has("can_view_organisation_usage", "outreach"))
    );
  },
  canManageOutreach() {
    return (
      (features.has("has_outreach") &&
        permissions.has("can_manage_outreach")) ||
      (features.has("has_outreach_2") &&
        permissions.has("can_view_quick_search_and_contacts", "outreach"))
    );
  },
  canViewRateCard() {
    return (
      features.hasAny([
        "media_request",
        "audio_transcript",
        "has_instant_edit_transcript",
        "has_instant_edit_media_item",
      ]) && permissions.has("can_view_ratecard")
    );
  },
};

export const mediaFeatures = {
  hasTvEyesBroadcast() {
    return features.hasAny([
      "has_tveyes_broadcast_anz",
      "has_tveyes_broadcast_us_canada",
      "has_tveyes_broadcast_uk",
      "has_tveyes_broadcast_europe",
      "has_tveyes_broadcast_rest_of_world",
      "has_tveyes_broadcast_canada",
    ]);
  },
  canViewMediaItems() {
    return features.hasAny(["media_request", "has_instant_edit_media_item"]);
  },
  canViewTranscriptRequests() {
    return features.hasAny([
      "audio_transcript",
      "has_instant_edit_transcript",
      "has_completed_transcripts_page",
    ]);
  },
  canViewMediaAndTranscriptRequests() {
    const globalStore = useGlobalStore();
    const { appConfig } = storeToRefs(globalStore);

    if (appConfig.value.platformName === "CisionOne") {
      return mediaFeatures.hasTvEyesBroadcast();
    }

    return (
      mediaFeatures.canViewMediaItems() &&
      mediaFeatures.canViewTranscriptRequests()
    );
  },
};

export const socialFeatures = {
  canViewSocialPlatform() {
    return (
      socialFeatures.canViewSocialAnalyse() ||
      socialFeatures.canViewSocialListen() ||
      socialFeatures.canViewSocialEngage()
    );
  },
  canViewSocialAnalyse() {
    return (
      appInstance.config.globalProperties.$isDesktop &&
      features.has("has_social_platform") &&
      socialFeatures.hasSocialPermission("can_use_analyse")
    );
  },
  canViewSocialListen() {
    return (
      features.has("has_social_platform") &&
      socialFeatures.hasSocialPermission("can_use_listen")
    );
  },
  canViewSocialEngage() {
    return (
      appInstance.config.globalProperties.$isDesktop &&
      features.has("has_social_engage") &&
      socialFeatures.hasSocialPermission("can_use_engage")
    );
  },
  canManageSocialEngage() {
    return (
      socialFeatures.canViewSocialEngage() &&
      socialFeatures.hasSocialPermission("can_approve_social_engage_posts")
    );
  },
  canAddSocialStream() {
    return (
      socialFeatures.canViewSocialListen() &&
      socialFeatures.hasSocialPermission("can_create_mention_stream")
    );
  },
  hasSocialPermission(permission) {
    return permissions.has(permission, "social");
  },
};

export const crmFeatures = {
  canViewCrmPlatform() {
    return (
      features.has("has_journalist_enquiries") &&
      permissions.has("can_manage_crm")
    );
  },
};

export const outreachFeatures = {
  canViewOutreachPlatform() {
    return (
      appInstance.config.globalProperties.$isDesktop &&
      features.hasAny(["has_outreach", "has_outreach_2"]) &&
      organisationFeatures.canManageOutreach()
    );
  },
  actionStatus(isFeatureEnabled, isPermissionEnabled) {
    const result = {
      isDisabled:
        !appInstance.config.globalProperties.$isDesktop ||
        !isFeatureEnabled ||
        !isPermissionEnabled,
      tooltipText: null,
    };

    if (!isFeatureEnabled) {
      if (features.has("has_outreach_search_only")) {
        result.tooltipText = getLocaleText(
          "outreach.tooltips.search_only_upgrade"
        );
      } else {
        result.tooltipText = getLocaleText(
          "outreach.tooltips.media_release_upgrade"
        );
      }
    } else if (!isPermissionEnabled) {
      result.tooltipText = getLocaleText("outreach.tooltips.no_permission");
    }

    return result;
  },
  defaultRouteName(to) {
    return to.params.product === "outreach"
      ? "outreach-launchpad"
      : "launchpad";
  },
  quickSearch() {
    return this.actionStatus(
      features.has("has_outreach_search_only"),
      permissions.has("can_view_quick_search_and_contacts", "outreach")
    );
  },
  search() {
    return this.actionStatus(
      features.has("has_outreach_activities_lists_search"),
      permissions.has("can_view_quick_search_and_contacts", "outreach")
    );
  },
  activities() {
    return this.actionStatus(
      features.has("has_outreach_activities_lists_search"),
      permissions.has("can_view_activities", "outreach")
    );
  },
  createActivities() {
    return this.actionStatus(
      features.has("has_outreach_activities_lists_search"),
      permissions.has("can_create_activities", "outreach")
    );
  },
  mediaReleaseAnalytics() {
    return this.actionStatus(
      features.has("has_outreach_media_releases"),
      permissions.has("can_view_media_release_and_analytics", "outreach")
    );
  },
  sendMediaRelease() {
    return this.actionStatus(
      features.has("has_outreach_media_releases"),
      permissions.has("can_send_media_release", "outreach")
    );
  },
  contactLists() {
    return this.actionStatus(
      features.has("has_outreach_activities_lists_search") ||
        features.has("has_outreach_mailing_lists"),
      permissions.has("can_manage_contact_list", "outreach")
    );
  },
  contactPrivateData() {
    return this.actionStatus(
      features.has("has_outreach_activities_lists_search"),
      permissions.has("can_view_activities", "outreach") &&
        permissions.has("can_manage_contact_list", "outreach")
    );
  },
  hasOutreachPermission(permission) {
    return permissions.has(permission, "outreach");
  },
};

export const teaserFeatures = {
  canViewSocialPlatformTeaser() {
    return (
      appInstance.config.globalProperties.$isDesktop &&
      features.has("has_feature_teasers") &&
      !features.has("has_social_platform")
    );
  },
  canViewOutreachPlatformTeaser() {
    return (
      appInstance.config.globalProperties.$isDesktop &&
      features.has("has_feature_teasers") &&
      !features.hasAny(["has_outreach", "has_outreach_2"]) &&
      permissions.has("can_manage_outreach")
    );
  },
};

export default ({ app }) => {
  permissions = new Permissions();
  appInstance = app;

  Object.assign(app.config.globalProperties, {
    $mediaFeatures: mediaFeatures,
    $socialFeatures: socialFeatures,
    $outreachFeatures: outreachFeatures,
    $teaserFeatures: teaserFeatures,
    $organisationFeatures: organisationFeatures,
  });
};
